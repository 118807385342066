import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const SEO = ({ title, description, article }) => {
    const { pathname } = useLocation()
    const { site } = useStaticQuery(query)

    const {
        defaultTitle, 
        defaultDescription,
        siteUrl,
    } = site.siteMetadata

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: "src/images/icon.png",
        url: `${siteUrl}${pathname}`, 
        keywords: "Schatzkammer, Lübeck, Ankauf, Verkauf, Entrümpelung, Haushaltsauflösung, Nachlassverwertung",
    }

    return (
        <Helmet title={seo.title}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="keywords" content={seo.keywords} />

            {seo.url && <meta property="url" content={seo.url} />}
            {(article ? true : null) && <meta property="type" content="article" />}
            {seo.title && <meta property="title" content={seo.title} />}
            {seo.description && (
            <meta property="description" content={seo.description} />
            )}
            {seo.image && <meta property="image" content={seo.image} />}
        </Helmet>
    )
}

export default SEO

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                defaultDescription: description
                siteUrl
            }
        }
    }
`

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}